<template>
  <div>
    <flixLoader v-if="!email" />
    <div v-if="email">
      <hr class="flix-html-hr"/>
      <div class="flix-form-group">
        <div class="flix-btn-group">
        <a href="#" class="flix-btn" :class="{'flix-btn-success': !blacklist, 'flix-btn-default': blacklist}" @click.prevent="setBlacklist">
          <flixIcon id="ok"/>
        </a>
        <a href="#" class="flix-btn" :class="{'flix-btn-danger': blacklist, 'flix-btn-default': !blacklist}" @click.prevent="setBlacklist">
          <flixIcon id="remove" />
        </a>
        </div>
        <a href="#" class="flix-btn flix-btn-default" @click.prevent="setBlacklist">
          {{ $tc('message.appointmentNotification', 2) }}
          <span v-if="blacklist" :class="{'flix-text-success': !blacklist, 'flix-text-danger': blacklist}" >
            {{ $tc('message.inactive', 2) }}
          </span>
          <span v-else :class="{'flix-text-success': !blacklist, 'flix-text-danger': blacklist}" >
            {{ $tc('message.active', 2) }}
          </span>
        </a>
      </div>
      <transition name="flixFadeIn">
        <div v-if="save" :key="save" class="flix-alert" :class="{'flix-alert-danger': blacklist, 'flix-alert-success': !blacklist}" v-html="$t('message.successfullSaved')" />
      </transition>
      <small class="flix-html-small flix-text-info"><flixIcon id="info-sign" /> {{ $tc('message.appointmentNotification', 2) }}: Hierüber können Sie den Emailversand bei neuen Terminbuchungen an <b>{{ email }}</b> deaktivieren. Sie werden also <b>nicht mehr per Email</b> darüber informiert.</small>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    id: {
      type: [String, Number],
      default () { return '' }
    }
  },
  data () {
    return {
      email: false,
      variables: this.$getUserVariables(),
      blacklist: false,
      save: false
    }
  },
  computed: {

  },
  mounted () {
    this.getCalendar()
  },
  methods: {
    setBlacklist () {
      var send = {
        email: this.email,
        group: JSON.stringify(['booking_info']),
        user: this.variables.user.ID
      }

      if (!this.blacklist) {
        send.status = true
      }

      this.$flix_post({
        url: 'blacklist/set',
        data: send,
        callback: function (ret) {
          this.getBlacklist()
          this.save = true
          setTimeout(function () { this.save = false }.bind(this), 3000)
        }.bind(this)
      })
    },
    getBlacklist () {
      this.$flix_post({
        url: 'blacklist/check',
        data: {
          user: this.variables.user.ID,
          to: this.email,
          group: 'booking_info'
        },
        callback: function (ret) {
          this.blacklist = ret.data
        }.bind(this)
      })
    },
    getCalendar () {
      this.$flix_post({
        url: 'assistent/get_data',
        data: {
          user: this.variables.user.md5_id,
          ID: this.id
        },
        callback: function (ret) { this.email = ret.data[1].contactEmail || this.variables.user.email; this.getBlacklist() }.bind(this)
      })
    }
  }
}
</script>
<style lang="sass" scoped>
</style>

import { render, staticRenderFns } from "./notificationBlacklist.vue?vue&type=template&id=276cd6d0&scoped=true"
import script from "./notificationBlacklist.vue?vue&type=script&lang=js"
export * from "./notificationBlacklist.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "276cd6d0",
  null
  
)

export default component.exports